import { SegmentTag } from './global';

export enum RequestTypes {
  DOCUMENT = 'DOCUMENT',
  CONTACT = 'CONTACT',
  SAMPLE = 'SAMPLE',
  QUOTE = 'QUOTE',
  CART_SAMPLE = 'CART_SAMPLE'
}

export enum ContactRequestContext {
  COVALO = 'COVALO',
  PROJECT = 'PROJECT',
  BRAND_PORTAL = 'BRAND_PORTAL',
  SUPPLIER_PORTAL = 'SUPPLIER_PORTAL'
}

export enum RequestsParentPage {
  LANDING_PAGE = 'LANDING_PAGE',
  DETAILS_PANEL = 'DETAILS_PANEL',
  COMPARE_MODAL = 'COMPARE_WINDOW',
  COMPARE_SNIPPET = 'COMPARE_SNIPPET'
}

export interface ProductApiIdentifier {
  company: { key: string };
  segment: SegmentTag;
  key: string;
}
[];
export interface ContactRequest {
  products: string[];
  type: RequestTypes;
  project?: any;
  request: {
    acknowledge?: boolean;
    email?: string;
    phone?: string;
    phoneNumber?: string;
    company?: string;
    country?: string;
    message?: string;
    lastName?: string;
    neededBy?: string; //TODO: string of type date
    firstName?: string;
    department?: string;
    includeCoa?: string;
    salutation?: string;
    foundingYear?: string;
    documentTypes?: string[];
    estimatedUsage?: string;
    estimatedUsageUnits?: string; // TODO: can be enum
    requiredClaims?: string;
    requiredFormat?: string;
    salesLocations?: string[];
    revenueLastYear?: string;
    shippingAddress?: {
      city: string;
      street: string;
      country: string;
      street2?: string;
      postcode: string;
    };
    quantityRequired?: string;
    quantityRequiredUnits?: string;
    comparableProduct?: string;
    otherDocumentType?: string;
    specificApplication?: string;
  };
}

// TODO
//for this Request Context interface we need to make it dynamic,
// for example if it is for a project then we shall pass the project
// if it is part of an event then we should pass the event key
// etc etc
export interface RequestContext {
  type: ContactRequestContext;
  portalKey?: string;
}

export interface LastRequest {
  company: string;
  country: string;
  department: string;
  email: string;
  estimateUsage: string;
  estimatedUsageUnits: string;
  firstName: string;
  includeCoa: boolean;
  incoterms: string;
  lastName: string;
  message: string;
  neededBy: string;
  packaging: string;
  packagingOther: string;
  phone: string;
  quantityRequired: string;
  quantityRequiredUnits: string;
  salutation: string;
  documentTypes: string[];
  otherDocumentType: string;
  phoneNumber: string;
  shippingAddress: {
    personOfContact?: string;
    person_of_contact?: string;
    company: string;
    city: string;
    country: string;
    countryCode: string;
    postcode: string;
    street: string;
    street2: string;
  };
}
