import {
  useGetInviteableCompanyOptions,
  useGetCompanyOptionsForEvent,
  useGetCompanyOptions
} from 'api/services/CompanyServiceNew';
import React, { useCallback } from 'react';
import { MuiAsyncSingleSelect } from './Select/MUIAsyncSelects/MuiAsyncSingleSelect';

export const CompanySelectorMui: React.FC<{
  onSelect: (value: { value: string; label: string }) => void;
  /**
   * Set to `true` to call the "invitable" endpoint.
   * - When `true`: Returns all companies that can the user has access to.
   * - Special case: If the user is `info@covalo.com`, it returns all companies.
   * - Use false if u dont want this special behavior
   */
  callInvitable?: boolean;
  /** The currently selected company, or `null` if none is selected. */
  value: { value: string; label: string } | null;

  /**
   * If provided, makes a brand portal API call.
   * - `eventKey`: Identifies the event.
   * - `params`: Additional parameters (any format, as Axios allows anything here).
   */
  brandPortalCall?: {
    eventKey: string;
    params: any;
  };
}> = ({ onSelect, value, brandPortalCall, callInvitable }) => {
  const [, refetch] = useGetCompanyOptions(0, '');
  const [, refetchInvitable] = useGetInviteableCompanyOptions(0, '');
  const [, refetchEvent] = useGetCompanyOptionsForEvent(
    0,
    '',
    brandPortalCall?.eventKey
  );
  const getCompanies = useCallback(
    (page: number, search: string) =>
      new Promise((resolve, reject) => {
        const fetchFunction = brandPortalCall
          ? refetchEvent
          : callInvitable
          ? refetchInvitable
          : refetch;

        fetchFunction({
          params: {
            page,
            ...(brandPortalCall?.params ?? {
              fields: 'name,key',
              sort: 'name,asc',
              operator: 'OR',
              key__icontains: search
            }),
            name__icontains: search
          }
        })
          .then(res => {
            resolve(
              res.data.content
                .filter(c => c.key !== 'givaudan')
                .map(c => ({ label: c.name, value: c.key }))
            );
          })
          .catch(() => {
            reject();
          });
      }),
    [brandPortalCall, refetchEvent, callInvitable, refetchInvitable, refetch]
  );

  return (
    <MuiAsyncSingleSelect
      onChange={value => value && onSelect(value)}
      label={'Select company'}
      getOption={getCompanies}
      propsValue={value}
      AutocompleteProps={{ disableCloseOnSelect: false }}
    />
  );
};
