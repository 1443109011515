export const linkValidationRegex =
  /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/;

export const latitudeValidationRegex = /^-?([0-8]?[0-9]|90)(\.[0-9]{1,15})$/;

export const longitudeValidationRegex =
  /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,15})$/;

export const casValidationRegex = /([0-9]{2,7})-([0-9]{2})-[0-9]/;

//export const FemaValidation = /^\d{3}-\d{3}-\d$/;
export const ecValidation = /^\d{3}-\d{3}-\d$/;
export const eNovalidation = /^E\d{3}$/;

export const emailValidation =
  /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w+)+$/;

export const isNumber = /\d+/g;

//valdid hexcode with 3 or 6 digits while '#' is optional
export const hexPattern = /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;

export const onlyNumbersByDigitPattern = (number: number) =>
  new RegExp(`^\\d{${number}}$`);

export const leadingNumbersWithDotOrBracket = /^(?:\d+\.)|(?:\d+\))/;
export const percentageWithSign = /^(\d{1,2}(\.\d{1,2})?|100)%$/;
export const isWebpage =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]+)?(\/[^\s]*)?$/;

//based from here   https://gist.github.com/brunodles/927fd8feaaccdbb9d02b and updated to support more cases
export const isYoutubeLink =
  /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]{11})\&?/;

export const validateEmailDomains = (email: string, domains: string[]) => {
  const parts = email.split('@');
  if (parts.length === 2) {
    const domain = parts[1];
    if (domains.length >= 1)
      return domains.some(
        emailDomain => emailDomain.toLowerCase() === domain.toLowerCase()
      );
  }
  return false;
};

export const domainValidation = new RegExp(
  '^[a-zA-Z0-9]+([\\-.]{1}[a-zA-Z0-9]+)*\\.[a-zA-Z]{2,5}$'
);
