import { Autocomplete, AutocompleteProps } from '@mui/material';
import { useMemo } from 'react';
import { Option } from './types';
import { useAsyncSelect } from './useAsyncSelect';

export const MuiAsyncSingleSelect = ({
  onChange,
  propsValue,
  getOption,
  placeholder,
  disabled,
  label,
  AutocompleteProps
}: {
  onChange: (newValue: Option | null) => void;
  label: string;
  getOption: (page: number, search: string) => Promise<any>;
  propsValue: Option | null;
  placeholder?: string;
  disabled?: boolean;
  AutocompleteProps?: Partial<AutocompleteProps<Option, false, any, undefined>>;
}) => {
  const { options, defaultProps, onListBoxScroll } = useAsyncSelect(
    getOption,
    placeholder ?? '',
    label
  );

  /**
   * Add the current value to the options, otherwise the component will complain that
   * values are missed in the options.
   */
  const realOptions = useMemo(() => {
    if (propsValue && !options.find(o => o.value === propsValue?.value)) {
      return [...options, propsValue];
    } else {
      return options;
    }
  }, [options, propsValue]);

  return (
    <Autocomplete
      {...defaultProps}
      options={realOptions}
      value={propsValue}
      disabled={disabled}
      onChange={(_, newValue) => {
        if (newValue) {
          return onChange({
            value: newValue.value,
            label: newValue.label
          });
        } else {
          return onChange(null);
        }
      }}
      ListboxProps={{ onScroll: onListBoxScroll }}
      {...AutocompleteProps}
    />
  );
};
